<template>
  <carousel
    :items="[
      {
        img: image1,
        title: 'Member Baru Diskon Ongkir 20%',
        description:
          'Tanpa syarat dan tanpa minimum order',
        icon: {
          component: 'ni ni-camera-compact text-dark',
          background: 'bg-white',
        },
      },
      {
        img: image2,
        title: 'Punya Produk? Ayo Join Partner.',
        description:
          'Kami memiliki ratusan ribu seller yang siap membantu menjual produk kamu sampai ludes terjual.',
        icon: {
          component: 'ni ni-bulb-61 text-dark',
          background: 'bg-white',
        },
      },
      {
        img: image3,
        title: 'Auto Tracking Kiriman Anda.',
        description:
          'Auto Detection Attempt Delivery Fail. Gerak cepat problem solving sehingga paket terkirim sukses.',
        icon: {
          component: 'ni ni-trophy text-dark',
          background: 'bg-white',
        },
      },
    ]"
  />
</template>

<script>
import Carousel from "@/components/Carousel.vue";
import image2 from "../assets/img/img-2.jpg";
import image1 from "../assets/img/img-1.jpg";
import image3 from "../assets/img/img-3.jpg";
export default {
  name: "CarouselInfo",
  components: {
    Carousel,
  },
  data() {
    return {
      image2,
      image1,
      image3,
    };
  },
};
</script>

<style></style>
